export const keytrudaGreen = '#6cc04a';
export const consumerGreen = '#48a446';
export const keytrudaGreenWeb = '#2C6D3C';
export const keytrudaGreenWebForBanner = '#36864A';
export const keytrudaGreenBackground = '#e7f8e0';

export const skyLight = '#a8dfff';
export const sky = '#70bdff';
export const blueberry = '#006ead';
export const blueberryDark = '#004a85';
export const ink = '#262626';
export const charcoal = '#444444';
export const slate = '#696969';
export const concrete = '#8c8c8c';
export const pebble = '#bebebe';
export const silver = '#dddddd';
export const mist = '#ebeaeb';
export const cloud = '#f3f3f3';
export const white = '#ffffff';
export const merckTeal = '#009999';
export const morning = '#6dceb2';
export const afternoon = '#86da64';
export const evening = '#28227e';
export const midnight = '#0c2340';
export const errorRed = '#db3933';
export const warningYellow = '#ffe105';
export const htmlPiBlack = '#000000';