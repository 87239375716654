import { configureStore } from '@reduxjs/toolkit';

import rootReducer, { INITIAL_STATE } from './reducers/root';

const store = configureStore({
    reducer: rootReducer,

    /**
     * @note If we ever move our root reducer to utilize `combineReducers`,
     * we'll need to keep the shape of the initial state to be the same
     * as our final reducer.
     */
    preloadedState: INITIAL_STATE,
});

export default store;
