import {
    allowScrolling,
    closeAllMenuItems,
    closeFilter,
    closeMobileMenu,
    closeOverlay,
    indicateMainNavIsNotPinned,
    indicateMainNavIsPinned,
    indicateScrolledPastInPageSSI,
    indicateNotScrolledPastInPageSSI,
    minimizeIndicationDrawerPreview,
    openFilter,
    openIndicationDrawerPreview,
    openMenuItem,
    openMobileMenu,
    openOverlay,
    preventScrolling,
    setMainNav,
    setState,
    toggleFilter,
    openHcpAcknowledgement,
    closeHcpAcknowledgement,
    openGeneralModal,
    closeGeneralModal,
    toggleIndicationDrawer,
    toggleSafetyDrawer,
    enableChatbot,
    openChatbot,
    closeChatbot,
    setUsableSpace,
} from '../actions';

export const INITIAL_STATE = {
    scrollLock: false,

    hcpAcknowledgementOpen: false,
    generalModalOpen: false,
    generalModalData: {},
    indicationDrawerPreviewOpen: false,
    scrolledPastInPageSSI: false,

    // Sticky ISI State
    indicationDrawerOpen: false,
    safetyDrawerOpen: false,

    /**
     * Can be a string that identifies a unique overlay created by `createOverlay`,
     * or true to open the "default" Overlay we have sitting in the DOM. When `false`,
     * all overlays are closed.
     * @type {String|Boolean}
     */
    overlayActive: false,

    mainNavigation: {
        openItem: null,
        mobileMenuOpen: false,
    },

    // "Headroom"
    mainNavIsPinned: false,

    // Customizer
    filterOpen: false,

    // Chatbot
    chatbotEnabled: false,
    chatbotOpen: false,

    // UsableSpace
    usableSpace: {},
};

/**
 * Keeping things simple by using a vanilla reducer function,
 * rather than Redux Toolkit's `createReducer` / `immer` or
 * separate states + combineReducers.
 *
 * We can look to incorporate these things later if this
 * simpler approach ends up being too hard to maintain.
 */
const rootReducer = (state, action) => {
    switch (action.type) {
        case setState.type:
            // For AT only
            return {
                ...state,
                ...action.payload,
            };

        case allowScrolling.type:
            return {
                ...state,
                scrollLock: false,
            };

        case preventScrolling.type:
            return {
                ...state,
                scrollLock: true,
            };

        case openHcpAcknowledgement.type:
            return {
                ...state,
                hcpAcknowledgementOpen: true,
            };

        case closeHcpAcknowledgement.type:
            return {
                ...state,
                hcpAcknowledgementOpen: false,
            };

        case openGeneralModal.type:
            return {
                ...state,
                generalModalOpen: true,
                generalModalData: action.payload,
            };

        case closeGeneralModal.type:
            return {
                ...state,
                generalModalOpen: false,
            };

        case openIndicationDrawerPreview.type:
            return {
                ...state,
                indicationDrawerPreviewOpen: true,
            };

        case minimizeIndicationDrawerPreview.type:
            return {
                ...state,
                indicationDrawerPreviewOpen: false,
            };

        case toggleIndicationDrawer.type:
            return {
                ...state,
                indicationDrawerOpen: !state.indicationDrawerOpen,
            };

        case toggleSafetyDrawer.type:
            return {
                ...state,
                safetyDrawerOpen: !state.safetyDrawerOpen,
            };

        case indicateScrolledPastInPageSSI.type:
            return {
                ...state,
                // Flag indicating the user has scrolled past the in-page SSI
                scrolledPastInPageSSI: true,
            };

        case indicateNotScrolledPastInPageSSI.type:
            return {
                ...state,
                // Flag indicating the user has not scrolled past the in-page SSI
                scrolledPastInPageSSI: false,
            };

        case setMainNav.type:
            return {
                ...state,
                mainNavigation: action.payload,
            };

        case openMenuItem.type:
            return {
                ...state,
                mainNavigation: {
                    ...state.mainNavigation,
                    openItem: action.payload,
                },
            };

        case closeAllMenuItems.type:
            return {
                ...state,
                mainNavigation: {
                    ...state.mainNavigation,
                    openItem: null,
                },
            };

        case openMobileMenu.type:
            return {
                ...state,
                mainNavigation: {
                    ...state.mainNavigation,
                    mobileMenuOpen: true,
                },
            };

        case closeMobileMenu.type:
            return {
                ...state,
                mainNavigation: {
                    ...state.mainNavigation,
                    mobileMenuOpen: false,
                },
            };

        case indicateMainNavIsPinned.type:
            return {
                ...state,
                // Updating this doesn't "pin" or "unpin" the nav, it just sync Headroom's internal state to redux
                mainNavIsPinned: true,
            };

        case indicateMainNavIsNotPinned.type:
            return {
                ...state,
                // Updating this doesn't "pin" or "unpin" the nav, it just sync Headroom's internal state to redux
                mainNavIsPinned: false,
            };

        case openOverlay.type:
            return {
                ...state,
                // Payload is the unique key associated with that overlay.
                // If none is passed, open the default overlay
                // (when created via `createOverlay('someKey', { is_default: true })`)
                overlayActive: action.payload ?? true,
            };

        case closeOverlay.type:
            return {
                ...state,
                overlayActive: false,
            };

        case toggleFilter.type:
            return {
                ...state,
                filterOpen: !state.filterOpen,
            };

        case openFilter.type:
            return {
                ...state,
                filterOpen: true,
            };

        case closeFilter.type:
            return {
                ...state,
                filterOpen: false,
            };
            
        // CHATBOT
        case enableChatbot.type:
            return {
                ...state,
                chatbotEnabled: true,
            };

        case openChatbot.type:
            return {
                ...state,
                chatbotOpen: true,
            };

        case closeChatbot.type:
            return {
                ...state,
                chatbotOpen: false,
            };

        case setUsableSpace.type:
            return {
                ...state,
                usableSpace: action.payload,
            };

        default:
            return state;
    }
};

export default rootReducer;
