/**
 * This file should export named integer constants.
 * That is, variables that can be used for `z-index`
 * properties within CSS.
 *
 * Then, to use this, simple import the variable and use it in your styled-component.
 * @example
 *     import { NAV_CONTAINER } from 'src/constants/z-index-map';
 *     const NavContainerLayout = styled.div`
 *       z-index: ${NAV_CONTAINER};
 *     `;
 */

export const HOMEPAGE_HERO_CONTENT_WRAPPER = 1;

export const ARE_YOU_AN_HCP_MODAL_OVERLAY = 50;
export const GENERAL_MODAL_OVERLAY = 49;

export const NAV_CONTAINER = 10;
export const NAV_CONTAINER_MOBILE_MENU_OPEN = 46;

export const DESKTOP_NAV_OVERLAY = 5;
export const MOBILE_NAV_OVERLAY = 45;

export const INDICATIONS_AND_SAFETY_CONTAINER = 40;
export const INDICATIONS_DRAWER_LAUNCH_BUTTON = 41;
export const INDICATIONS_INFORMATION_DRAWER = 42;
export const SAFETY_DRAWER_LAUNCH_BUTTON = 43;
export const SAFETY_INFORMATION_DRAWER = 44;

export const STYLED_OVERLAY = 9;

// Make sure this is above the nav
export const INDICATIONS_AND_SAFETY_DRAWER_OVERLAY = 13;

export const MAIN_NAV_DESKTOP = 2;
export const MAIN_NAV_MOBILE = 0;
export const MAIN_NAV_MAIN_MENU_LIST = 10;

export const UTILITY_NAV_MENU = 3;

export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_BOTTOM = 45;
export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_MIDDLE = 46;
export const ONETRUST_CCPA_COOKIE_BANNER_OVERRIDE_TOP = 47;